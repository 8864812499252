<template>
  <div class="configuration-view" v-loading="loading">
    <div class="engine" ref="engine"/>
    <div class="tool-bar" :class="{active: $route.query.showTool}">
      <el-tooltip :content="$t('page.back')" placement="right">
        <i class="tool-icon el-icon-back" @click="handleBack"/>
      </el-tooltip>
      <el-tooltip :content="$t('page.fitWindow')" placement="right">
        <i class="tool-icon c-icon" @click="handleFitView(true)"></i>
      </el-tooltip>
      <el-tooltip :content="$t('page.fitView')" placement="right">
        <i class="tool-icon c-icon" @click="handleFitView(false)"></i>
      </el-tooltip>
      <el-tooltip :content="$t('page.normalSize')" placement="right">
        <i class="tool-icon c-icon" @click="handleResetView"></i>
      </el-tooltip>
      <el-slider
        v-model="scale"
        @change="handleScaleChange"
        vertical
        :min="0.1"
        :step="0.1"
        :max="3"
        height="200px"
      />
    </div>
  </div>
</template>
<script>
import {ConfigurationEngine, registerEcharts} from "@/components/ConfigurationEngine";
import {detail} from "@/api/page";


export default {
  name: "ShowV2",
  
  data() {
    return {
      loading: false,
      scale: 1
    }
  },
  
  mounted() {
    this.engine = new ConfigurationEngine(this.$refs.engine)
    registerEcharts(this.$echarts)
    this.engine.on('*', this.handleEngineEvent)
    this.loadData()
    window.addEventListener("resize", this.handleResize)
  },
  
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize)
    this.engine.off('*', this.handleEngineEvent)
    this.engine.destroy()
  },
  
  methods: {
    loadData() {
      let id = this.$route.query.id
      if (id) {
        this.loading = true
        detail({id: id}).then(res => {
          let detail = res.data
          let data = {
            grid: true,
            gridSize: 100,
            rule: true,
            pens: []
          }
          if (detail.v2Data) {
            try {
              data = JSON.parse(detail.v2Data)
            } catch (e) {
            }
          }
          this.engine?.open(JSON.parse(JSON.stringify(data)))
          this.engine.lock(1)
          this.engine.fitView(true, 10)
        }).finally(() => {
          setTimeout(() => {
            this.loading = false
          }, 500)
        })
      }
    },
    
    handleResize() {
      setTimeout(() => {
        this.handleFitView(true)
      }, 100)
    },
    
    handleFitView(fit) {
      this.engine.fitView(fit, 10)
      if (!fit) {
        if (!this.engine.hasView()) return;
        const rect = this.engine.getRect();
        const pensRect = this.engine.getPenRect(rect);
        const {scale, origin, x: dataX, y: dataY} = this.engine.store.data;
        this.engine.translate(
          (0 - origin.x) / scale - pensRect.x - dataX / scale,
          (0 - origin.y) / scale - pensRect.y - dataY / scale
        );
      }
    },
    
    handleScaleChange(val) {
      this.engine.scale(val)
    },
    
    handleResetView() {
      this.engine.scale(1)
      this.engine.centerView()
    },
    
    handleBack() {
      this.$router.back()
      setTimeout(() => {
        if (window.location.hash.indexOf("#/show/v2") == 0) {
          window.location.reload()
        }
      }, 1000)
    },
    
    handleEngineEvent(event, data) {
      switch (event) {
        case 'scale':
          this.scale = data
          break
      }
    }
  }
}
</script>
<style scoped lang="scss">
.configuration-view {
  height: 100vh;
  position: relative;
  
  .engine {
    width: 100%;
    height: 100%;
  }
  
  .tool-bar {
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: 2px 0 4px #dad7d7;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;
    opacity: 0;
    transition: all 0.5s;
    
    .tool-icon {
      font-size: 20px;
      cursor: pointer;
      margin-bottom: 8px;
      
      &:hover {
        color: #409EFF;
      }
    }
    
    &.active,
    &:hover {
      opacity: 1;
      width: 50px;
    }
  }
}
</style>